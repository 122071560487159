import React, { useEffect, useState } from "react";
import { searchImages } from "../../Services.tsx";
import { fetchApi, IMAGE_BASE_URL } from "../../Utils/Connection.tsx";
import { Image } from "./Images.tsx";
import { getCategories } from "../Categories/Services.tsx";
import { Category } from "../Categories/Categories.tsx";
import Spinner from "../../Components/Spinner.tsx";
import DeleteDiaglogue from "../../Components/DeleteDiaglogue.tsx";
import SuccessModal from "../../Components/SuccessModal.tsx";
import ErrorModal from "../../Components/ErrorModal.tsx";

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;

  image?: any;
};

const ImageDetails = ({ isModalOpen, setIsModalOpen, image }) => {
  const [selectedImages, setSelectedImages] = useState([]);

  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState("");
  const [tags, setTags] = useState("");
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const getCats = async () => {
    const cats = await getCategories();
    setCategories(cats);
  };

  useEffect(() => {
    if (isModalOpen) {
      getCats();
    }
    if (image) {
      setTags(image.tags.join(", "));
      setSelectedCategories(image.categories.join(", "));
    }
  }, [isModalOpen]);

  // Function to toggle modal visibility
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const onPressCategory = (e) => {
    if (selectedCategories.includes(e.target.value)) {
      setSelectedCategories(selectedCategories.replace(e.target.value, ""));
      return;
    }
    setSelectedCategories((prev) => prev + "," + e.target.value);
  };

  const updateItem = async () => {
    const body = {
      category: selectedCategories.split(",").filter((item) => item),
      tags: tags.split(",").map((tag) => tag.trim()),
    };
    setLoading(true);

    const res = await fetchApi(`creator/images/${image._id}`, "PUT", body);

    if (res) {
      setSuccess(true);
      toggleModal();
    } else {
      setError(true);
    }
    setLoading(false);
  };

  const onPressDeleteImg = async () => {
    setLoading(true);
    const res = await fetchApi(`creator/images/${image._id}`, "DELETE");

    if (res) {
      toggleModal();
      setDeleteModal(false);
      setSuccess(true);
    } else {
      setError(true);
    }
    setLoading(false);
  };

  return (
    <div className="relative flex flex-col min-h-screen bg-[#111418]">
      {/* Modal Structure */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          {/* Modal container with max height and scrollable content */}
          <div className="relative bg-[#111418] text-white rounded-lg shadow-lg w-[95%] max-h-[90vh] overflow-y-auto p-6">
            <header className="flex items-center justify-between border-b border-solid border-b-[#293038] px-10 py-3">
              <button
                onClick={toggleModal}
                className="ml-auto text-white font-bold"
              >
                X
              </button>
            </header>

            {/* Modal Body */}
            <div className="px-40 py-5">
              {/* Set this div width to 95% */}
              <div className="flex flex-col w-[95%] mx-auto">
                <div className="text-[32px] font-bold mb-6">Edit image</div>

                <label className="flex flex-col min-w-40 flex-1">
                  <p className="text-white text-base font-medium leading-normal pb-2">
                    Tags
                  </p>
                  <textarea
                    value={tags}
                    onChange={(e) => setTags(e.target.value)}
                    placeholder="Add tags to help categorize your data"
                    className="form-input flex w-full min-w-0 flex-1 resize-none overflow-hidden rounded-xl text-white focus:outline-0 focus:ring-0 border border-[#3c4753] bg-[#1c2126] focus:border-[#3c4753] min-h-36 placeholder:text-[#9dabb8] p-[15px] text-base font-normal leading-normal"
                  ></textarea>
                </label>
                <label className="flex flex-col min-w-40 flex-1">
                  <p className="text-white text-base font-medium leading-normal pb-2">
                    Category
                  </p>
                  <select
                    onClick={onPressCategory}
                    multiple
                    className="form-input flex w-full min-w-0 flex-1 resize-none overflow-auto rounded-xl text-white focus:outline-0 focus:ring-0 border border-[#3c4753] bg-[#1c2126] focus:border-[#3c4753] h-14 placeholder:text-[#9dabb8] p-[15px] text-base font-normal leading-normal appearance-none"
                    style={{
                      backgroundImage: "none",
                      height: "auto",
                      maxHeight: "200px",
                    }} // Set maxHeight for scrolling
                  >
                    <option value="" disabled>
                      Select a category
                    </option>
                    {categories.map((cat: Category) => (
                      <option
                        key={cat._id}
                        value={cat._id}
                        selected={selectedCategories.includes(cat._id)}
                      >
                        {cat.name}
                      </option>
                    ))}
                  </select>
                </label>

                <div
                  className="relative aspect-video bg-cover rounded-xl bg-no-repeat cursor-pointer"
                  style={{
                    backgroundImage: `url(${IMAGE_BASE_URL}${image?.image})`,
                  }}
                ></div>
              </div>
              <div className="flex max-w-[480px] gap-4 px-4 py-3">
                <button
                  onClick={() => setDeleteModal(true)}
                  className="flex-1 bg-[#293038] text-white py-3 rounded-xl"
                >
                  Delete
                </button>
                <button
                  onClick={() => updateItem()}
                  className="flex-1 bg-[#1980e6] text-white py-3 rounded-xl"
                >
                  Update Item
                </button>
              </div>
              {loading && <Spinner />}
            </div>
          </div>
        </div>
      )}
      <DeleteDiaglogue
        title="Delete Image"
        message="Are you sure you want to delete this image?"
        visible={deleteModal}
        setVisible={setDeleteModal}
        onDelete={onPressDeleteImg}
        loading={loading}
      />
      <SuccessModal
        title="Success"
        message="Image updated successfully"
        visible={success}
        setVisible={setSuccess}
      />
      <ErrorModal
        title="Error"
        message="An error occurred. Please try again"
        visible={error}
        setVisible={setError}
      />
    </div>
  );
};

export default ImageDetails;
