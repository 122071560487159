import React, { useRef, useState } from "react";
import { fetchMedia } from "../../Utils/Connection.tsx";
import { addCreator } from "./Services.tsx";

const AddCreator = () => {
  const [name, setName] = useState<string>("");
  const [url, setUrl] = useState<string>("");

  const onSubmit = async () => {
    if (!name || !url) {
      alert("Please fill in all fields");
      return;
    }

    try {
      const body = {
        name,
        url,
      };

      const res = await addCreator(body);

      if (res) {
        alert("Creator created successfully");
      } else {
        alert("Failed to create Creator");
      }
    } catch (error) {
      console.error(error);
      alert("Failed to create Creator");
    }
  };

  return (
    <div
      className="relative flex size-full min-h-screen flex-col bg-[#111418] dark group/design-root overflow-x-hidden"
      style={{ fontFamily: 'Inter, "Noto Sans", sans-serif' }}
    >
      <div className="layout-container flex h-full grow flex-col">
        <div className="px-40 flex flex-1 justify-center py-5">
          <div className="layout-content-container flex flex-col w-[512px] max-w-[512px] py-5 max-w-[960px] flex-1">
            <div className="flex flex-wrap justify-between gap-3 p-4">
              <p className="text-white tracking-light text-[32px] font-bold leading-tight min-w-72">
                Create new creator
              </p>
            </div>

            <div className="flex max-w-[480px] flex-wrap items-end gap-4 px-4 py-3">
              <label className="flex flex-col min-w-40 flex-1">
                <p className="text-white text-base font-medium leading-normal pb-2">
                  Name
                </p>
                <input
                  placeholder="E.g. Gianna Kurda"
                  className="form-input flex w-full min-w-0 flex-1 resize-none overflow-hidden rounded-xl text-white focus:outline-0 focus:ring-0 border border-[#3c4753] bg-[#1c2126] focus:border-[#3c4753] h-14 placeholder:text-[#9dabb8] p-[15px] text-base font-normal leading-normal"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </label>
            </div>
            <div className="flex max-w-[480px] flex-wrap items-end gap-4 px-4 py-3">
              <label className="flex flex-col min-w-40 flex-1">
                <p className="text-white text-base font-medium leading-normal pb-2">
                  Creator URL
                </p>
                <input
                  placeholder="https://giannakurda.com"
                  className="form-input flex w-full min-w-0 flex-1 resize-none overflow-hidden rounded-xl text-white focus:outline-0 focus:ring-0 border border-[#3c4753] bg-[#1c2126] focus:border-[#3c4753] h-14 placeholder:text-[#9dabb8] p-[15px] text-base font-normal leading-normal"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                />
              </label>
            </div>
            <div className="flex px-4 py-3">
              <div className="flex px-4 py-3">
                <button
                  onClick={onSubmit}
                  className="flex min-w-[84px] max-w-[450px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 flex-1 bg-[#1980e6] text-white text-sm font-bold leading-normal tracking-[0.015em]"
                >
                  <span className="truncate">Submit</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCreator;
