import { fetchApi } from "../../Utils/Connection.tsx";

export const getCreators = async () => {
    const res = await fetchApi("creators", "GET");
    return res;
}

export const addCreator = async (body: any) => {
    const res = await fetchApi("creators", "POST", body);
    return res
}