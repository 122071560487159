import { fetchApi } from "../../Utils/Connection.tsx";

export const getCreatorWallPapers = async (page: number, limit: number) => {
  try {
    const res = await fetchApi(`creator/images/${page}/${limit}`, "GET");

    return res;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const searchCreatorWallPapers = async (
  page: number,
  limit: number,
  search: string
) => {
  try {
    const res = await fetchApi(`creator/images/search/${page}/${limit}`, "POST", {
      search,
    });

    return res;
  } catch (error) {
    console.error(error);
    return [];
  }
};
