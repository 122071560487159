import React, { useRef, useState } from "react";
import { fetchMedia } from "../../Utils/Connection.tsx";

const CreateCategoryPage = () => {
  const [imgFile, setImgFile] = useState<File | null>(null); // Store the file instead of base64 string
  const [name, setName] = useState<string>("");
  const selectInputRef = useRef<HTMLInputElement>(null);

  const onSubmit = async () => {
    if (!name || !imgFile) {
      alert("Please fill in all fields");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("image", imgFile); 

      const res = await fetchMedia("categories", formData);

      if (res) {
        alert("Category created successfully");
      } else {
        alert("Failed to create category");
      }
    } catch (error) {
      console.error(error);
      alert("Failed to create category");
    }
  };

  return (
    <div
      className="relative flex size-full min-h-screen flex-col bg-[#111418] dark group/design-root overflow-x-hidden"
      style={{ fontFamily: 'Inter, "Noto Sans", sans-serif' }}
    >
      <div className="layout-container flex h-full grow flex-col">
        <div className="px-40 flex flex-1 justify-center py-5">
          <div className="layout-content-container flex flex-col w-[512px] max-w-[512px] py-5 max-w-[960px] flex-1">
            <div className="flex flex-wrap justify-between gap-3 p-4">
              <p className="text-white tracking-light text-[32px] font-bold leading-tight min-w-72">
                Create new category
              </p>
            </div>
            <p className="text-white text-base font-normal leading-normal pb-3 pt-1 px-4">
              Enter a name for your new category.
            </p>
            <div className="flex max-w-[480px] flex-wrap items-end gap-4 px-4 py-3">
              <label className="flex flex-col min-w-40 flex-1">
                <p className="text-white text-base font-medium leading-normal pb-2">
                  Name
                </p>
                <input
                  placeholder="E.g. Summer 2023"
                  className="form-input flex w-full min-w-0 flex-1 resize-none overflow-hidden rounded-xl text-white focus:outline-0 focus:ring-0 border border-[#3c4753] bg-[#1c2126] focus:border-[#3c4753] h-14 placeholder:text-[#9dabb8] p-[15px] text-base font-normal leading-normal"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </label>
            </div>
            <div className="flex px-4 py-3">
              <button
                onClick={() => {
                  selectInputRef.current?.click();
                }}
                className="flex min-w-[84px] max-w-[450px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 flex-1 bg-[#1980e6] text-white text-sm font-bold leading-normal tracking-[0.015em]"
              >
                <span className="truncate">Select Image</span>
              </button>

              <input
                ref={selectInputRef}
                type="file"
                accept="image/*"
                className="hidden"
                onChange={(e) => {
                  if (e.target.files && e.target.files[0]) {
                    setImgFile(e.target.files[0]); // Save the file object
                  }
                }}
              />
            </div>
            {imgFile && (
              <img
                src={URL.createObjectURL(imgFile)} // Show the selected image using Object URL
                alt="Selected"
                className="w-380 h-450 rounded-xl object-cover ml-3"
              />
            )}
            <div className="flex px-4 py-3">
              <button
                onClick={onSubmit}
                className="flex min-w-[84px] max-w-[450px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 flex-1 bg-[#1980e6] text-white text-sm font-bold leading-normal tracking-[0.015em]"
              >
                <span className="truncate">Submit</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCategoryPage;
