import React, { useEffect, useMemo, useRef, useState } from "react";
import { getCategories } from "../Categories/Services.tsx";
import { Category } from "../Categories/Categories.tsx";
import { fetchMedia } from "../../Utils/Connection.tsx";
import { getCreators } from "../Creators/Services.tsx";
import Spinner from "../../Components/Spinner.tsx";
import SuccessModal from "../../Components/SuccessModal.tsx";
import ErrorModal from "../../Components/ErrorModal.tsx";

const UploadDataPage = () => {
  const [categories, setCategories] = useState([]);

  const [selectedImages, setSelectedImages] = useState<File[]>([]); // [File, File, File, ...

  const [loading, setLoading] = useState(false);
  const selectInputRef = useRef<any>();

  const [tags, setTags] = useState(""); // "tag1, tag2, tag3, ..."
  const [category, setCategory] = useState(""); // "category_id"
  const [progress, setProgress] = useState(0);
  const [successModal, setSuccessModal] = useState(false);
  const [error, setError] = useState(false);
  const fetchCat = async () => {
    const cat = await getCategories();
    setCategories(cat);
  };

  const onSelectCategory = (e) => {
    // check if the category is already selected
    if (category.includes(e.target.value)) {
      // remove the category
      setCategory(category.replace(e.target.value, ""));
      return;
    }
    setCategory((prev) => prev + "," + e.target.value);
  };

  useEffect(() => {
    fetchCat();
  }, []);

  const onSubmit = async () => {
    const body = {
      category: category.split(",").filter((item) => item),
      tags: tags.split(",").map((tag) => tag.trim()),
      images: selectedImages,
    };

    const formData = new FormData();
    formData.append("categories", JSON.stringify(body.category));
    formData.append("tags", JSON.stringify(body.tags));

    body.images.forEach((image) => {
      formData.append("images", image);
    });
    setLoading(true);
    try {
      const res = await fetchMedia("creator/images", formData, (event) => {
        const percentage = Math.round((event.loaded * 100) / event.total);
        setProgress(percentage); // Update the progress state
      });

      if (res) {
        setSuccessModal(true);
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    }

    setLoading(false);
    setSelectedImages([]);
    setTags("");
    setCategory("");
    setProgress(0); // Reset progress after completion
  };

  const imageComponent = useMemo(() => {
    return selectedImages.map((item: File) => (
      <div
        key={item.name}
        className="w-full gap-1 overflow-hidden bg-[#111418] @[480px]:gap-2 aspect-[3/2] rounded-xl grid grid-cols-[2fr_1fr_1fr]"
      >
        <div
          className="w-full bg-center bg-no-repeat bg-cover aspect-auto rounded-none row-span-2"
          style={{
            backgroundImage: `url(${URL.createObjectURL(item)})`,
          }}
        ></div>
        <div className="flex flex-col gap-1 p-2">
          <p className="text-white text-base font-medium leading-normal">
            {item.name}
          </p>
          <p className="text-[#9dabb8] text-sm font-normal leading-normal">
            {Math.round(item.size / 1024)}KB {item.type}
          </p>
        </div>
      </div>
    ));
  }, [selectedImages]);

  return (
    <div
      className="relative flex size-full min-h-screen flex-col bg-[#111418] dark group/design-root overflow-x-hidden"
      style={{
        "--select-button-svg":
          "url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2724px%27 height=%2724px%27 fill=%27rgb(157,171,184)%27 viewBox=%270 0 256 256%27%3e%3cpath d=%27M181.66,170.34a8,8,0,0,1,0,11.32l-48,48a8,8,0,0,1-11.32,0l-48-48a8,8,0,0,1,11.32-11.32L128,212.69l42.34-42.35A8,8,0,0,1,181.66,170.34Zm-96-84.68L128,43.31l42.34,42.35a8,8,0,0,0,11.32-11.32l-48-48a8,8,0,0,0-11.32,0l-48,48A8,8,0,0,0,85.66,85.66Z%27%3e%3c/path%3e%3c/svg%3e')",
        fontFamily: "Inter, Noto Sans, sans-serif",
      }}
    >
      <div className="layout-container flex h-full grow flex-col">
        <div className="px-40 flex flex-1 justify-center py-5">
          <div className="layout-content-container flex flex-col max-w-[960px] flex-1">
            <div className="flex flex-wrap justify-between gap-3 p-4">
              <div className="flex min-w-72 flex-col gap-3">
                <p className="text-white text-4xl font-black leading-tight tracking-[-0.033em]">
                  Upload Data
                </p>
                <p className="text-[#9dabb8] text-base font-normal leading-normal">
                  Add your data to a dataset
                </p>
              </div>
            </div>
            <div className="flex max-w-[480px] flex-wrap items-end gap-4 px-4 py-3">
              <label className="flex flex-col min-w-40 flex-1">
                <p className="text-white text-base font-medium leading-normal pb-2">
                  Category
                </p>
                <select
                  multiple
                  onChange={onSelectCategory}
                  className="form-input flex w-full min-w-0 flex-1 resize-none overflow-auto rounded-xl text-white focus:outline-0 focus:ring-0 border border-[#3c4753] bg-[#1c2126] focus:border-[#3c4753] h-14 placeholder:text-[#9dabb8] p-[15px] text-base font-normal leading-normal appearance-none"
                  style={{
                    backgroundImage: "none",
                    height: "auto",
                    maxHeight: "200px",
                  }} // Set maxHeight for scrolling
                >
                  <option value="" disabled>
                    Select a category
                  </option>
                  {categories.map((cat: Category) => (
                    <option key={cat._id} value={cat._id}>
                      {cat.name}
                    </option>
                  ))}
                </select>
              </label>
            </div>

            <div className="flex max-w-[480px] flex-wrap items-end gap-4 px-4 py-3">
              <label className="flex flex-col min-w-40 flex-1">
                <p className="text-white text-base font-medium leading-normal pb-2">
                  Tags
                </p>
                <textarea
                  value={tags}
                  onChange={(e) => setTags(e.target.value)}
                  placeholder="Add tags to help categorize your data"
                  className="form-input flex w-full min-w-0 flex-1 resize-none overflow-hidden rounded-xl text-white focus:outline-0 focus:ring-0 border border-[#3c4753] bg-[#1c2126] focus:border-[#3c4753] min-h-36 placeholder:text-[#9dabb8] p-[15px] text-base font-normal leading-normal"
                ></textarea>
              </label>
            </div>

            <button
              onClick={() => selectInputRef.current.click()}
              className="flex max-w-[480px] flex-wrap items-end gap-4 px-4 py-3 cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 bg-[#293038] text-white gap-2 text-sm font-bold leading-normal tracking-[0.015em] min-w-0 px-2.5"
            >
              <div
                className="text-white"
                data-icon="Plus"
                data-size="20px"
                data-weight="regular"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20px"
                  height="20px"
                  fill="currentColor"
                  viewBox="0 0 256 256"
                >
                  <path d="M136,136V88a8,8,0,0,0-16,0v48H88a8,8,0,0,0,0,16h32v48a8,8,0,0,0,16,0V152h48a8,8,0,0,0,0-16Z"></path>
                </svg>
              </div>
              <span className="truncate">Select Images</span>
            </button>
            <div className="flex w-full grow bg-[#111418] @container p-4">
              {imageComponent}
            </div>
            <div className="flex justify-stretch">
              <div className="flex flex-1 gap-3 flex-wrap px-4 py-3 justify-end">
                <button className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 bg-[#293038] text-white text-sm font-bold leading-normal tracking-[0.015em]">
                  <span className="truncate">Cancel</span>
                </button>
                <button
                  disabled={loading}
                  onClick={onSubmit}
                  className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 bg-[#1980e6] text-white text-sm font-bold leading-normal tracking-[0.015em]"
                >
                  <span className="truncate">Upload</span>
                </button>
                {loading && (
                  <>
                    <Spinner />
                    <div className="w-full bg-gray-200 rounded-full">
                      <div
                        className="bg-blue-500 text-xs leading-none py-1 text-center text-white rounded-full"
                        style={{ width: `${progress}%` }}
                      >
                        {progress}%
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <input
        ref={selectInputRef}
        type="file"
        multiple
        className="hidden"
        onChange={(e) => {
          const files = e.target.files;
          if (files) {
            setSelectedImages([...selectedImages, ...Array.from(files)]);
          }
        }}
      />
      <SuccessModal
        title="Success"
        message="Images uploaded successfully"
        setVisible={() => setSuccessModal(false)}
        visible={successModal}
      />
      <ErrorModal
        title="Error"
        message="An error occured while uploading images"
        setVisible={() => setError(false)}
        visible={error}
      />
    </div>
  );
};

export default UploadDataPage;
